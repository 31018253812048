import React from "react"
import { Link } from "gatsby"
import Tilt from "react-tilt"
import BackgroundImage from "gatsby-background-image"
import { AllHtmlEntities as entities } from "html-entities"

const SummaryJoc = ({ title, slug, image }) => {
  title = entities.decode(title)
  return (
    <Link to={`jocs/${slug}/`}>
      <Tilt options={{ max: 25 }} className="Tilt">
        <BackgroundImage fluid={image}>
          <div className="Tilt-inner joc-hero rounded h-40 md:h-48 p-4 md:p-6 flex items-end">
            <h2 className="font-bold text-2xl text-white z-20">{title}</h2>
          </div>
        </BackgroundImage>
      </Tilt>
    </Link>
  )
}

export default SummaryJoc
