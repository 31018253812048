import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import SummaryJoc from "../layouts/jocs/summary"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="jocsdetaula.club - 🎲 Jocs de taula en català" />
    <Header />
    <section className="pt-32 pb-8">
      <div className="max-w-3xl mx-auto px-4">
        <h1 className="mb-2 font-bold text-2xl lg:text-4xl leading-tight text-orange-900">
          Jocs de taula en català
        </h1>
        <p className="text-lg">
          T'agraden els jocs de taula? Tota la informació, crítiques i ofertes
          sobre els millors jocs de taula en català: Catan, Carcassone, Codi
          Secret, L'Illa Prohibida, Dixit… Troba enllaços on comprar tots
          aquests jocs de taula en català al millor preu! Vols regalar un joc de
          taula en català? Aquí hi trobaràs les millors recomanacions i ofertes!
        </p>
      </div>
    </section>
    <section className="py-8">
      <div className="container">
        <ul className="flex flex-wrap -mx-4">
          {data.allWordpressWpJocs.nodes.map(node => (
            <li className="w-full sm:w-1/2 lg:w-1/3 mb-8 px-4">
              <SummaryJoc
                title={node.title}
                slug={node.slug}
                image={node.featured_media.localFile.childImageSharp.fluid}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
    allWordpressWpJocs(sort: { fields: [menu_order] }) {
      nodes {
        title
        slug
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 430) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
